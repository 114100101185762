.education {
    flex-direction: column;
    max-width: 90vw;
}

img.um-logo {
    max-width: 70vw;
    max-height: 20rem;
    border-radius: 20%;
}

.education-degree {
    text-align: center;
    font-weight: bolder;
    font-size: 2rem;
    margin: 1.5rem 0;
}

.education-dates {
    font-size: 1.5rem;
    justify-content: flex-end;
}

.education-school-name {
    font-weight: bold;
    font-style: italic;
    font-size: 1.5rem;
}

.education-location {
    font-style: italic;
    font-size: 1.5rem;
}
