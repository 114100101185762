// GENERAL

.fade-in {
    animation: fadein 3s;
    -o-animation: fadein 3s;
    -ms-animation: fadein 3s;
    -moz-animation: fadein 3s;
    -webkit-animation: fadein 3s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.general-card {
    margin: 1rem;
    width: 25rem;
    max-width: 90vw;
    background-color: white;
}

.card-subtitle {
    text-align: center;
    margin-bottom: 1rem;
}

// JobCard
.job-card {
    min-height: 31rem;
    max-height: 40rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    opacity: 1;
    transition: opacity 5s;
    cursor: pointer;
}

.job-card:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 1), 0 6px 20px 0 rgba(0, 0, 0, 0.99);
    transition: box-shadow 0.5s linear;
}
