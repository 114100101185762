.name-title {
    text-align: center;
    font-size: 5rem;
    line-height: 1;
    margin-bottom: 1rem;
}

.personal-details {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 80vw;

    * {
        margin: 0.3rem 1rem;
    }
}

.home-page-nav-buttons {
    margin: 1rem;
}

h2 {
    margin: 1rem;
}

.skill-card-layout {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.skill-card {
    margin: 0.75rem;
    padding: 0.5rem;
    border-radius: 1rem;
}

.skill-gradient {
    display: flex;
    flex-direction: row;

    span {
        margin: 0.5rem;
    }

    div {
        width: 10rem;
        height: 2rem;
        background: linear-gradient(90deg, black 0%, hsl(88, 73%, 91%) 100%);
        border: 1px solid black;
    }
}
