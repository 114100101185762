$body-bg: #4b5043;
$secondary: #a5907e;
$primary: #5e0b15;

@import '~bootstrap/scss/bootstrap';

div {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #eceef8;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.page-title {
    font-size: 3rem;
    text-align: center;
}
